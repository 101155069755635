import { IEntity } from '../types'

export enum ObjectType {
  Car = 'Car',
  Bicycle = 'Bicycle',
  Person = 'Person',
  Roadsign = 'Roadsign',
  Motorcycle = 'Motorcycle',
  Animal = 'Animal'
}
export enum AreaType {
  Directed = 'Directed',
  Bidirectional = 'Bidirectional'
}
export enum AssignmentStatus {
  Stopped = 'Stopped',
  NotStarted = 'NotStarted',
  Started = 'Started',
  Starting = 'Starting',
  Stopping = 'Stopping',
  Rejected = 'Rejected'
}
export enum AssignmentEventStatus {
  NotHandled = 'NotHandled',
  FalsePositive = 'FalsePositive',
  Handled = 'Handled',
  ViolationDetected = 'ViolationDetected',
  Alert = 'ViolationDetected'
}
export enum AssignmentEventType {
  MotionDetected = 'MotionDetected',
  NoMovement = 'NoMovement',
  CameraRotationDetected = 'CameraRotationDetected',
  SterileAreaEnter = 'SterileAreaEnter',
  SterileAreaLeave = 'SterileAreaLeave',
  BorderCrossed = 'BorderCrossed',
  DefocusDetected = 'DefocusDetected',
  DisappearObjectDetected = 'DisappearObjectDetected',
  AbandonedObjectsDetected = 'AbandonedObjectsDetected',
  VehicleTransitDetected = 'VehicleTransitDetected',
  ImageTooBlurryDetected = 'ImageTooBlurryDetected',
  ImageTooBlurryReset = 'ImageTooBlurryReset',
  ImageTooDarkDetected = 'ImageTooDarkDetected',
  ImageTooDarkReset = 'ImageTooDarkReset',
  ImageTooBrightDetected = 'ImageTooBrightDetected',
  ImageTooBrightReset = 'ImageTooBrightReset',
  GlobalSceneChangeDetected = 'GlobalSceneChangeDetected',
  GlobalSceneChangeReset = 'GlobalSceneChangeReset',
  SignalLossDetected = 'SignalLossDetected',
  SignalLossReset = 'SignalLossReset',
  MotionAlarmDetected = 'MotionAlarmDetected',
  MotionAlarmReset = 'MotionAlarmReset'
}
type IAvailableLocales = 'Ru'
export interface IAssignmentParameters {
  area?: string
  minCount?: number
  syncMode?: 'File' | 'All' | 'Metadata'
  points?: Array<[number, number]>
  threshold?: number
  areaType?: AreaType
  typesForControl?: ObjectType[]
  sensorType?: 'Thor' | 'Thor-X'
  host?: string
  user?: string
  password?: string
  connectionTimeout?: number
  stateHistoryTTL?: number
  syncFrom?: string
  syncTo?: string
  useSectionSpeed?: boolean
  mergeAssignmentId?: string
  previewGenerationDelay?: number
}
export interface IAssignment extends IEntity {
  title: string
  type: 'Assignment'
  entityId: string
  cameraId: string
  assignmentTypeId: string
  assignmentTypeTitle: string
  entityType: string | 'Camera'
  status: AssignmentStatus
  canStart?: boolean
  groupId?: string
  rejectReason?: string
  resourceId?: string
  parameters?: IAssignmentParameters
}
export interface IBaseAssignmentEvent extends IEntity {
  id: string
  assignmentId: string
  timestamp: number
  handlingTimeout?: number
  eventType: AssignmentEventType
  status: AssignmentEventStatus
  startedAt?: number
  endAt?: number | null
  bboxes?: Array<[number, number, number, number]>
  assignment?: IAssignment & {
    entityTitle: string
    priorityTitle: string
    priorityIconColor: string
  }
}
export interface IAssignmentEvent extends IBaseAssignmentEvent {
  type: 'AssignmentEvent'
}
export interface IAssignmentType extends IEntity {
  title: string
  name: string
  iconClass?: string
  iconColor?: string
  icon?: {
    iconClass: string
    color: string
  }
}
export type IAssignmentEventType = {
  id: string
  type: 'AssignmentEventType'
  name: string
  title: string
  counting: boolean
  startsWith?: string
  endsWith?: string
  iconClass?: string
  iconColor?: string
  icon?: {
    iconClass: string
    color: string
  }
} & {
  [k in `locale${IAvailableLocales}`]?: Partial<IAssignmentEventTypeLocale>
}
export type IAssignmentEventTypeLocale = {
  title: string
  countTitle: string
  countUnit: Array<[string, string]>
}
export interface IAssignmentEventCounter extends IBaseAssignmentEvent {
  type: 'AssignmentEventCounter'
  count: number
  assignment?: IBaseAssignmentEvent['assignment'] & {
    parameters: {
      typesForControl: ObjectType[]
    }
  }
}
export interface IBorderCrossEvent extends IAssignmentEventCounter {
  eventType: AssignmentEventType.BorderCrossed
  direction: 'Direct' | 'Reverse'
}
