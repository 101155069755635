import { ExtendedError } from './types'

export class NotFoundError {
  status = 404
  message = ''
  constructor(message: string) {
    this.message = message
  }
}

export class CubeJSError {
  status = 500
  message = 'Something went wrong'
  constructor(error: unknown) {
    if ('status' in (error as ExtendedError)) {
      this.status = Number((error as ExtendedError).status)
      this.message = (error as ExtendedError).message
    }
  }
}

export class ApiRepoErrors {
  static handle(status: number, message: string) {
    switch (status) {
      case 401:
        !location.pathname.includes('login') && location.reload()
        break
    }
    return new Error(message)
  }
}
