import { Connection, getFiwareHeaders, getNgsiConnection } from '@netvision/lib-api'

// TODO
// Написать тесты

type Permission = {
  entityId: string
  resourceId: string
  scopes: string[]
}

export interface PermissionBatchOptions {
  disableCache: boolean
}

export type BatchPermissionArguments = Omit<Permission, 'resourceId'>

export class PermissionManager {
  private getFiwareHeaders = getFiwareHeaders
  private getNgsiConnection = getNgsiConnection
  private PERMISSION_PATH = '/gateway/auth/user/permissions'

  /**
   * WeakMap<Map, timerId>
   */
  private TIMER_KEY = new WeakMap<typeof this.PERMISSIONS_INCOME_MAP, number>()
  /**
   * Map<entityId, [resolve, reject]>
   */
  private RESOLVERS_MAP = new Map<string, [(params: unknown) => void, (params: any) => void][]>()
  /**
   * Map<entityId, scopes[]>
   */
  private PERMISSIONS_INCOME_MAP = new Map<string, Set<string>>()
  /**
   * Map<entityId, permission>
   */
  private PERMISSION_CACHE = new Map<string, Record<string, any>>()

  private async getPermissionByResource<T>(resources: BatchPermissionArguments[]) {
    const gatewayHeaders = await this.getFiwareHeaders()
    const { url } = (await this.getNgsiConnection()) as unknown & {
      url: {
        origin: string
      }
    } & Connection

    const response = await fetch(`${url.origin}${this.PERMISSION_PATH}`, {
      headers: {
        ...gatewayHeaders,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        mode: 'Permissions',
        resources
      })
    })

    return (await response.json()) as T[]
  }

  private async checkPermission(key: string, options?: PermissionBatchOptions) {
    const keys = [...this.PERMISSIONS_INCOME_MAP.keys()]
    const keysInCache: string[] = []
    const keysWithOutCache: string[] = []
    keys.forEach((key) =>
      this.PERMISSION_CACHE.has(key) && !options?.disableCache
        ? keysInCache.push(key)
        : keysWithOutCache.push(key)
    )

    const permissionsFromCache = keysInCache.map((id) => this.PERMISSION_CACHE.get(id)) as Record<
      string,
      any
    >[]

    let permissionsFromServer: Record<string, any>[] = []

    try {
      if (keysWithOutCache.length) {
        permissionsFromServer = await this.getPermissionByResource(
          keysWithOutCache.map((id) => {
            const scopes = this.PERMISSIONS_INCOME_MAP.get(id)
            return {
              entityId: id,
              scopes: Array.from(scopes || [])
            }
          })
        )
      }

      const resultMap = new Map(
        permissionsFromCache.concat(permissionsFromServer).map(({ entityId, ...rest }) => {
          this.PERMISSION_CACHE.set(entityId, { ...rest, entityId })
          return [entityId, { ...rest, entityId }]
        })
      )

      this.RESOLVERS_MAP.get(key)?.forEach(([resolve]) => resolve(resultMap))
    } catch (e) {
      const resolvers = this.RESOLVERS_MAP.get(key)
      resolvers?.forEach(([, reject]) => reject(e))
    } finally {
      this.RESOLVERS_MAP.delete(key)
      keys.forEach((key) => this.PERMISSIONS_INCOME_MAP.delete(key))
    }
  }

  getPermissionScopesByEntityId(
    resources: BatchPermissionArguments[],
    key: string,
    options?: PermissionBatchOptions
  ) {
    if (!Array.isArray(resources)) throw Error('resources is not array')
    if (this.TIMER_KEY.has(this.PERMISSIONS_INCOME_MAP)) {
      clearTimeout(this.TIMER_KEY.get(this.PERMISSIONS_INCOME_MAP))
      this.TIMER_KEY.delete(this.PERMISSIONS_INCOME_MAP)
    }

    resources.forEach((resource: BatchPermissionArguments) => {
      if (this.PERMISSIONS_INCOME_MAP.has(resource.entityId)) {
        const scopes = this.PERMISSIONS_INCOME_MAP.get(resource.entityId)
        this.PERMISSIONS_INCOME_MAP.set(
          resource.entityId,
          new Set([...(scopes || []), ...resource.scopes])
        )
        return
      }

      this.PERMISSIONS_INCOME_MAP.set(resource.entityId, new Set(resource.scopes))
    })

    return new Promise((resolve, reject) => {
      this.RESOLVERS_MAP.set(
        key,
        this.RESOLVERS_MAP.has(key)
          ? [...(this.RESOLVERS_MAP.get(key) || []), [resolve, reject]]
          : [[resolve, reject]]
      )

      this.TIMER_KEY.set(
        this.PERMISSIONS_INCOME_MAP,
        window.setTimeout(() => this.checkPermission(key, options), 300)
      )
    })
  }
}

const permissionManager = new PermissionManager()
export { permissionManager }
