import {
  getEntity,
  listEntities,
  createEntity,
  deleteEntity,
  updateEntityAttributes,
  getUserPermittedScopes,
  getUserPermissionsByIdsMap,
  addOnUnauthorizedListener,
  getCredentialsHistory,
  getServerContext,
  isBadRequestError,
  isAuthenticationError,
  isServerError,
  isRequestError,
  getUserInfo,
  batchQuery,
  batchUpdate,
  login,
  loginByAccessToken,
  logout,
  IRequestError,
  getFiwareHeaders,
  getNgsiConnection,
  notificationSocket,
  addOnBadRequestListener,
  Connection
} from '@netvision/lib-api'
import {
  IBatchQueryPayload,
  IBatchUpdatePayload,
  IEntitiesListResponse,
  IEntity,
  IEntityResponse,
  IListPayload,
  ILoginCheckResponse,
  ISearchQueryPayload,
  IUserInfo,
  IServerInfo,
  IPreviewPayload,
  ILibApiRepo,
  CameraMediaResources,
  ILoginBody
} from '../../types'

import { EventPreviewService } from './EventPreviewService'
import { permissionManager, PermissionManager } from './PermissionManager'
import type { BatchPermissionArguments, PermissionBatchOptions } from './PermissionManager'

type EntityQueue = Map<IEntity['id'], IBatchQueryPayload['filter']['entities'][number]>

const ENTITIES_QUEUE_MAP: Map<string, EntityQueue> = new Map()
const ENTITY_ID_RESOLVER: Map<string, [(entity: any) => void, (error: unknown) => void][]> =
  new Map()
const KEY_ATTRS_MAP: Map<string, Set<string>> = new Map()
const KEY_TIMEOUT_MAP: Map<string, ReturnType<typeof setTimeout>> = new Map()
const MERGED_FILTERS = new Map()

export class LibApiRepository implements ILibApiRepo {
  private eventPreviewService: EventPreviewService
  private permissionManager: PermissionManager

  constructor() {
    this.eventPreviewService = new EventPreviewService(getFiwareHeaders, getNgsiConnection)
    this.permissionManager = permissionManager
  }

  #sanitizeQuery(value: string | number | null) {
    // eslint-disable-next-line no-useless-escape
    return typeof value === 'string' ? value.replace(/[^a-zа-я0-9 .,\-]/gi, '') : value
  }
  #insensitifyQuery(value: string | number | null) {
    return typeof value === 'string'
      ? value
          .split('')
          .map((char) =>
            char.match(/[a-zа-я]/i) ? `[${char.toLowerCase()}${char.toUpperCase()}]` : char
          )
          .join('')
      : value
  }
  #qValueBuilder(payload: ISearchQueryPayload) {
    if (!payload.value) return ''
    const sanitized = payload.sanitize && this.#sanitizeQuery(payload.value)
    const insensitified = payload.insensitify && this.#insensitifyQuery(sanitized || payload.value)
    return insensitified || sanitized || payload.value
  }
  async #addToBatchDebounce(key: string) {
    clearTimeout(KEY_TIMEOUT_MAP.get(key))
    KEY_TIMEOUT_MAP.set(
      key,
      setTimeout(async () => {
        const entities = Array.from(ENTITIES_QUEUE_MAP.get(key)?.values() || [])
        const attrs = Array.from(KEY_ATTRS_MAP.get(key) || [])
        if (!entities || !attrs) return
        try {
          const { results } = await this.batchQuery({
            filter: {
              entities,
              attrs
            },
            limiter: {
              limit: 1000
            }
          })
          results.forEach((entity: IEntity) => {
            const resolvers = ENTITY_ID_RESOLVER.get(entity.id)
            if (!resolvers) return
            resolvers.forEach(([resolve]) => resolve(entity))
            ENTITY_ID_RESOLVER.delete(entity.id)
          })
        } catch (error) {
          entities.forEach((entity) => {
            const id = entity.id || entity.idPattern || ''
            const rejecters = ENTITY_ID_RESOLVER.get(id)
            if (!rejecters) return
            rejecters.forEach(([_, reject]) => reject(error))
            ENTITY_ID_RESOLVER.delete(id)
          })
        } finally {
          ENTITIES_QUEUE_MAP.delete(key)
          KEY_ATTRS_MAP.delete(key)
        }
      }, 300)
    )
  }

  async getHeaders() {
    const headers = await getFiwareHeaders()
    return headers.reduce<Record<string, string>>((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {})
  }
  async checkLoggedIn(): Promise<ILoginCheckResponse> {
    const response = await getServerContext()
    const userInfo = await this.getUserInfo()
    if (
      !isBadRequestError(response) &&
      !isAuthenticationError(response) &&
      !isServerError(response)
    ) {
      return {
        isUserLogged: !!response.service,
        serverInfo: response as unknown as IServerInfo,
        userInfo
      }
    }
    return { isUserLogged: !!response.service }
  }
  async fetchCredentialsHistory() {
    return await getCredentialsHistory()
  }

  addOnBadRequestListener(fn: (response: Response) => void) {
    return addOnBadRequestListener(fn as () => void)
  }

  unAuthListener(fn: () => void) {
    return addOnUnauthorizedListener(fn)
  }
  async getUserInfo(): Promise<IUserInfo | IRequestError> {
    return await getUserInfo()
  }
  login(body: ILoginBody, options?: RequestInit): Promise<boolean> {
    return login(body, options).then((response) => {
      if (response && !isRequestError(response)) {
        return true
      } else {
        if (isBadRequestError(response) || isAuthenticationError(response)) {
          throw new Error('wrong_data')
        } else if (isServerError(response)) {
          throw new Error('server_error')
        }

        throw new Error('unexpected_error')
      }
    })
  }
  loginByToken(credentials: Parameters<typeof loginByAccessToken>[0]) {
    return loginByAccessToken(credentials).then((response) => {
      if (response && !isRequestError(response)) {
        return true
      } else {
        if (isBadRequestError(response) || isAuthenticationError(response)) {
          throw new Error('wrong_data')
        } else if (isServerError(response)) {
          throw new Error('server_error')
        }

        throw new Error('unexpected_error')
      }
    })
  }

  logout(): Promise<boolean> {
    return logout().then((response) => response === true)
  }

  async getAccessToken() {
    try {
      const fireWareHeaders = await getFiwareHeaders()
      const { url } = (await getNgsiConnection()) as unknown & {
        url: {
          origin: string
        }
      } & Connection

      const response = await fetch(`${url.origin}/gateway/auth/accessToken/`, {
        headers: {
          ...fireWareHeaders,
          'Content-Type': 'application/json'
        }
      })
      
      return response.json() as Promise<{
        access_token: string
      }>
    } catch (e) {
      return Promise.reject(e)
    }
  }

  getEntity<T = IEntity>(payload: IEntity): Promise<IEntityResponse<T>> {
    return getEntity({
      ...payload,
      keyValues: true
    }).then((response) => response as unknown as IEntityResponse<T>)
  }
  async getEntitiesList<T>(payload: IListPayload): Promise<IEntitiesListResponse<T>> {
    try {
      if (typeof payload.limiter.keyValues === 'undefined') {
        payload.limiter.keyValues = true
      }
      const response = await listEntities({
        ...(payload.limiter || {}),
        ...(payload.filter || {}),
        q:
          typeof payload.filter?.q === 'string'
            ? payload.filter?.q
            : payload.filter?.q?.reduce(
                (acc, next, i, arr) =>
                  acc +
                  `${next.key}${next.operator || ''}${this.#qValueBuilder(next)}${
                    i < arr.length - 1 ? ';' : ''
                  }`,
                ''
              )
      })
      return response as unknown as IEntitiesListResponse<T>
    } catch (error: unknown) {
      console.error(error)
      throw error
    }
  }
  async appendEntity(payload: IEntity) {
    const c = await getNgsiConnection()
    return await c.v2.appendEntityAttributes(payload, { keyValues: true })
  }
  createEntity(payload: IEntity): Promise<IEntity> {
    return createEntity(payload, { keyValues: true }).then((response) => response.entity)
  }
  updateEntity(payload: IEntity): Promise<IEntityResponse> {
    return updateEntityAttributes(payload, { keyValues: true }).then((response) => ({
      ...response,
      entity: {} as IEntity
    }))
  }
  async deleteEntity(payload: IEntity): Promise<boolean> {
    return (await deleteEntity(payload)) && true
  }
  async getPermissions(payload: string[]): Promise<string[]> {
    return await getUserPermittedScopes(payload)
  }
  async getPermissionsByIdsMap(payload: string[]): Promise<Map<string, string[]>> {
    return await getUserPermissionsByIdsMap(payload)
  }
  async batchUpdate(payload: IBatchUpdatePayload): Promise<ReturnType<typeof batchUpdate>> {
    payload.limiter.keyValues = true
    return await batchUpdate(payload.filter, payload.limiter)
  }
  async batchQuery(payload: IBatchQueryPayload): Promise<ReturnType<typeof batchQuery>> {
    payload.limiter.keyValues = true
    return await batchQuery(payload.filter, payload.limiter)
  }
  async getEntitiesWithGlobalBatch(
    entity: IBatchQueryPayload['filter']['entities'][number],
    attrs: string[],
    key: string
  ): Promise<CameraMediaResources> {
    const queue = ENTITIES_QUEUE_MAP.get(key)
    const keyAttrs = KEY_ATTRS_MAP.get(key)
    const id = entity.id || entity.idPattern || ''
    if (queue) {
      queue.set(id, entity)
      keyAttrs && KEY_ATTRS_MAP.set(key, new Set([...keyAttrs, ...attrs]))
    } else {
      ENTITIES_QUEUE_MAP.set(key, new Map([[id, entity]]))
      KEY_ATTRS_MAP.set(key, new Set(attrs))
    }

    return new Promise((resolve, reject) => {
      const resolvers = ENTITY_ID_RESOLVER.get(id)
      ENTITY_ID_RESOLVER.set(
        id,
        resolvers ? [...resolvers, [resolve, reject]] : [[resolve, reject]]
      )
      this.#addToBatchDebounce(key)
    })
  }
  getNotificationSocket() {
    return notificationSocket
  }
  async fileUpload<T>(data: Record<string, any>, payload?: IEntity & { subEntity?: string }) {
    const formData = new FormData()
    const { file } = data
    const hasFile = file instanceof File
    if (!hasFile || !file?.name) throw new Error('bad file in data.file')
    if (!payload?.id || !payload.type) throw new Error('id or type does not exist in payload')

    Object.entries(data).forEach(([name, value]) => formData.append(name, value))
    const result = await this.eventPreviewService.uploadFile(payload, formData)
    return (await result.json()) as T
  }
  getPreview(config: IPreviewPayload): Promise<Blob | null> {
    return this.eventPreviewService.loadPreview(config).then((blob) => blob)
  }

  /**
   * @param resources - BatchPermissionArguments[]
   * @param key - Всегда название виджета стянутое из package.json
   */
  async getPermissionWithGlobalBatch(
    resources: BatchPermissionArguments[],
    key: string,
    options: PermissionBatchOptions
  ) {
    return await this.permissionManager.getPermissionScopesByEntityId(resources, key, options)
  }

  async getMergedQueryBatch<T>(
    payload: IListPayload,
    key = 'default'
  ): Promise<IEntitiesListResponse<T>> {
    const { limiter, filter } = payload
    const actualKey = `${key}-merged`

    if (!filter?.q || typeof filter?.q === 'string') return { results: [] }

    const mergedFilters: ISearchQueryPayload[] = MERGED_FILTERS.get(actualKey) || filter.q
    let localFilter: ISearchQueryPayload[] = []
    if (mergedFilters.length) {
      localFilter = filter.q.reduce((acc, next) => {
        const keyIndex = mergedFilters.findIndex(
          ({ key, operator }) => next.key === key && operator === '=='
        )
        if (keyIndex !== -1) {
          const hasSameKey = mergedFilters[keyIndex]
          const oldValue = String(hasSameKey.value).split(',')
          const nextValue = String(next.value).split(',')
          next.value = [...new Set([...oldValue, ...nextValue])].join(',')
          mergedFilters.splice(keyIndex, 1)
        }

        acc.push(next)
        return acc
      }, [] as ISearchQueryPayload[])
    }
    localFilter.push(...mergedFilters)
    MERGED_FILTERS.set(actualKey, localFilter)
    const lastResolvers = ENTITY_ID_RESOLVER.get(actualKey) || []
    return new Promise((resolve, reject) => {
      ENTITY_ID_RESOLVER.set(actualKey, [...lastResolvers, [resolve, reject]])
      clearTimeout(KEY_TIMEOUT_MAP.get(actualKey))
      KEY_TIMEOUT_MAP.set(
        actualKey,
        setTimeout(async () => {
          try {
            const result = await this.getEntitiesList<T>({
              filter: {
                q: localFilter
              },
              limiter
            })

            ENTITY_ID_RESOLVER.get(actualKey)?.forEach(([resolve]) => resolve(result))
          } catch (e) {
            ENTITY_ID_RESOLVER.get(actualKey)?.forEach(([, reject]) => reject(e))
          } finally {
            MERGED_FILTERS.delete(actualKey)
            KEY_TIMEOUT_MAP.delete(actualKey)
          }
        }, 300)
      )
    })
  }
}
