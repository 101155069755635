import cubejs, { LoadMethodOptions, Query } from '@cubejs-client/core'
import { ICubeApiMixin, IEntitiesListResponse } from '../../types'
import { ApiRepoErrors, CubeJSError, NotFoundError } from '../../errors'

export class CubeMixin implements ICubeApiMixin {
  private _cubejsApi
  private _splitResponseKeys = <T extends Record<string, unknown>>(
    entities: T[],
    mainEntityName?: string
  ): T[] =>
    entities.map((row) =>
      Object.entries(row).reduce((acc, [key, value]) => {
        let entityKey = ''
        let valueKey
        ;[entityKey, valueKey] = key.split('.')

        if (mainEntityName && entityKey !== mainEntityName) {
          const preparedKey = entityKey + valueKey.charAt(0).toUpperCase() + valueKey.slice(1)
          valueKey = preparedKey.charAt(0).toLowerCase() + preparedKey.slice(1)
        }

        // @ts-ignore
        acc[valueKey] = value
        return acc
      }, {} as T)
    )

  constructor() {
    if (window.CUBEJS_API_URL) {
      this._cubejsApi = cubejs('SECRET', {
        apiUrl: window.CUBEJS_API_URL
      })
    }
  }

  async cubeGetQuantity(
    payload: Query,
    mainEntityName?: string,
    options?: LoadMethodOptions
  ): Promise<number> {
    try {
      const res = await this._cubejsApi?.load(payload, options)
      if (!res) {
        const notFoundError = new NotFoundError('Nothing was found')
        throw notFoundError
      }

      return (
        this._splitResponseKeys(res.rawData(), mainEntityName).reduce(
          (acc, { count }) => (acc += Number(count || 0)),
          0
        ) || 0
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async cubeGetEntities<T>(
    payload: Query,
    mainEntityName?: string,
    options?: LoadMethodOptions
  ): Promise<IEntitiesListResponse<T>> {
    try {
      const res = await this._cubejsApi?.load(payload, options)
      if (!res) {
        const notFoundError = new NotFoundError('Nothing was found')
        throw notFoundError
      }
      return {
        results: this._splitResponseKeys(res.rawData(), mainEntityName)
      }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  handleError(error: unknown) {
    if (error instanceof NotFoundError) {
      return ApiRepoErrors.handle(Number(error.status), error.message)
    } else {
      const { status, message } = new CubeJSError(error)
      return ApiRepoErrors.handle(status, message)
    }
  }
}
